import { For, Match, Show, Switch, VoidComponent } from "solid-js";
import { TicketsService } from "../../../gen/webapi/v1/ticket_connect";
import { queryClient, useClient } from "../../api-client";
import { timestampFormatted } from "../../time-format";
import { AmountWithIcon } from "../ui/Amount";
import { createQuery, QueryClientProvider } from "@tanstack/solid-query";
import ErrorMessage from "../ui/Error";
import { SpinnerXS } from "../ui/Spinner";
import type { RecentResultsResponse_TicketWithOptionalResult } from "../../../gen/webapi/v1/ticket_pb";

const client = useClient(TicketsService);

const RecentTickets: VoidComponent = () => {
    return <QueryClientProvider client={queryClient}>
        <RecentTicketsActual />
    </QueryClientProvider>
}


const RecentTicketsActual: VoidComponent = () => {
    const query = createQuery(() => ['recentTickets'],
        () => client.recentResults({}),
        {
            refetchInterval: 10_000,
            staleTime: Infinity,
            retry: 1,
        })

    const multiple = (result: RecentResultsResponse_TicketWithOptionalResult) => {
        return `${Number((result.winAmount / result.ticket!.price).toFixed(2))}x`
    }

    return <div class="overflow-hidden rounded-lg border-[1px] border-slate-200 dark:border-slate-800">
        <table class="table-fixed lg:table-auto border-collapse w-full">
            <thead class="text-[10px] lg:text-xs">
                <tr class="bg-slate-300 dark:bg-[#1e283a] uppercase font-medium leading-normal tracking-widest">
                    <th class="p-2 md:px-3 text-left">Wallet</th>
                    <th class="p-2 hidden md:table-cell">Ticket price</th>
                    <th class="p-2 hidden md:table-cell">Multiple</th>
                    <th class="p-2 md:px-3">Winnings</th>
                    <th class="p-2 md:px-3 break-words">Timestamp</th>
                </tr>
            </thead>

            <tbody class="rounded-es-lg rounded-ee-lg bg-white dark:bg-slate-900/30">
                <Show when={query.data?.tickets} fallback={
                    <Switch>
                        <Match when={query.isLoading && !query.isFetched}>
                            <tr><td colSpan={3}>
                                <p class="p-4 text-sm animate-pulse flex items-center justify-start gap-2">
                                    Loading <SpinnerXS />
                                </p>
                            </td>
                            <td class="hidden md:table-cell"></td>
                            <td class="hidden md:table-cell"></td>
                            </tr>
                        </Match>

                        <Match when={query.isError || (query.isFetching && !query.data)}>
                            <tr><td colSpan={3} class="p-4 text-center">
                                <ErrorMessage text="Error loading bets" />
                            </td></tr>
                        </Match>
                    </Switch>
                }>
                    <For each={query.data?.tickets} fallback={
                        <tr class="text-sm leading-loose">
                            <td colSpan={3}>
                                <p class="p-4 text-center">No recent tickets found</p>
                            </td>
                        </tr>
                    }>{result =>
                        <tr class="text-sm leading-loose hover:bg-slate-200/50 dark:hover:bg-slate-900/20 transition-colors duration-300">
                            <td class="p-2 md:px-2 xl:px-4 text-ellipsis overflow-hidden w-6 md:w-fit" title={result.ticket?.walletAddr}>
                                <a class="link-hover font-mono" href={`/contest/?id=${result.ticket?.contestId}`}>{result.ticket?.walletAddr}</a>
                            </td>
                            <td class="p-2 md:px-4 hidden md:table-cell w-4 text-center">
                                <AmountWithIcon token={result.ticket?.token!} value={result.ticket?.price!} />
                            </td>
                            <td class="p-2 md:px-4 hidden md:table-cell w-4 text-center">
                                {multiple(result)}
                            </td>

                            <td class="p-2 md:px-4 w-4">
                                <Show when={!result.isLive} fallback={
                                    <span class="bg-indigo-300 dark:bg-indigo-700 font-semibold shadow-inner text-[10px] px-2 py-[5px] rounded tracking-wider">LIVE</span>
                                }>
                                    <span class="flex justify-start">
                                        <AmountWithIcon token={result.ticket?.token!}
                                            value={Number(result.winAmount?.toFixed(2))} />

                                        <span class="md:hidden ms-1 font-semibold">
                                            ({multiple(result)})
                                        </span>
                                    </span>
                                </Show>
                            </td>

                            <td class="p-2 md:px-4 text-center">
                                <Show when={!result.isLive} fallback={
                                    timestampFormatted(result.ticket?.purchaseTime?.toDate())
                                }>
                                    {timestampFormatted(result.declarationTime?.toDate())}
                                </Show>
                            </td>
                        </tr>
                        }</For>
                </Show >

            </tbody>
        </table>
    </div>
}

export default RecentTickets;